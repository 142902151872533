
import { Navigate, useLocation } from 'react-router-dom';
import {
  useParticipantAuth
} from 'core'

export default function RequiresParticipantAuth ({ children }) {
  const { currentSession } = useParticipantAuth();
  const location = useLocation();

  if (!currentSession.current) return <Navigate to="/login" state={{ from: location }} replace />;

  return children;
}