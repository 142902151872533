import { Route, Routes, Navigate } from 'react-router-dom'

import RequiresParticipantAuth from 'core/providers/require-auth'
import { ParticipantAuthProvider, DocumentProvider } from 'core'
import { HomePage } from 'pages/home'

export default function homeRoutes() {
  return (
    <DocumentProvider>
      <Routes>
        <Route path="/" element={<RequiresParticipantAuth><HomePage /></RequiresParticipantAuth>}>
          <Route path=":category" element={<HomePage />} />
        </Route>
      </Routes>
    </DocumentProvider>
  )
}
