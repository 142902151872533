import React from 'react'
import { Card } from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { DocumentField, SubmissionPage } from 'components'
import { randomUUID } from 'utils'
import SignatureCanvas from 'react-signature-canvas'
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import { Document, DocElementType } from 'core/models'
import Typography from "@mui/material/Typography";
import { cl } from '@twipped/utils';
import './styles.scss'

export function SectionEl({
  className,
  section,
  sectionIdx,
  sectionCount,
  activeSection,
  setActiveSection,
  signature,
  sigDataURL,
  setSigDataURL,
  branding,
  companyName,
  address,
  orderId,
  partId,
  checkNextStep,
  isShowAlertMessage,
  isShowSubmissionPage,
  setShowSubmissionPage
}) {
  const sigCanvas = React.useRef({})
  const [hideOldSignature, setHideOldSignature] = React.useState(false)
  const [renderCount, setRenderCount] = React.useState(0)
  const [showSnackbar, setShowSnackbar] = React.useState(false)
  const [snackbarType, setSnackbarType] = React.useState('success')
  const [alertContent, setAlertConent] = React.useState('')
  const [sigRequried, setSigRequried] = React.useState(false)

  const clearSignature = () => {
    if (hideOldSignature)
      sigCanvas.current.clear();
    else
      setHideOldSignature(true)
    setSigDataURL(null)
  }

  const saveSignature = () => {
    sigCanvas.current.getTrimmedCanvas().toBlob(async (blob) => {
      if (blob.size < 1000) {
        setSigRequried(true)
        return
      }

      const file = new File([blob], "signature.png");
      const {result} = await Document.uploadSignature(file)
      setSigRequried(false)
      setShowSnackbar(true)
      setSigDataURL(sigCanvas.current.getTrimmedCanvas().toDataURL('image/png'))
      if (result.status == 'success') {
        setAlertConent('Successfully Saved Signature')
        setSnackbarType('success')
      } else {
        setAlertConent('Failure Saving Signature')
        setSnackbarType('error')
      }
    });
  }

  const handleComplete = async () => {
    if ((!hideOldSignature && !signature) || (hideOldSignature && !sigDataURL)) {
      setSigRequried(true)
      return
    }

    if (checkNextStep()) {
      setShowSubmissionPage(true);
      setSigRequried(false)
      setActiveSection(activeSection, true);
    }
  }

  const handleNextStep = () => {
    if (checkNextStep())
      setActiveSection(activeSection + 1);
    setRenderCount(renderCount+1)
  }

  const closeSnackbar = () => {
    setShowSnackbar(false);
  };

  const lastSection = sectionCount - 1
  if(activeSection == sectionIdx && !hideOldSignature){
    window.scrollTo(0, 0);
  }
  return (
    <>
      <SubmissionPage
        show={isShowSubmissionPage}
        setShow={setShowSubmissionPage}
        partId={partId}
      />
      <Card className={className}>
        <CardHeader>
          <h3>{section.section_label}</h3>
        </CardHeader>
        <CardContent className='card-content'>
          <form className='inline-form'>
            {(section?.groups ?? []).map((group, groupIdx) => {
              const elements = group.elements ?? []
              return (
                <React.Fragment key={group.group_id}>
                  <hr/>
                  <h4>{group.group_label}</h4>
                  {elements.map((element, elementIdx) => {
                    return (
                      <DocumentField
                        key={randomUUID()}
                        element={element} 
                        orderId={orderId}
                        renderCount={renderCount}/>
                    )
                  })}
                </React.Fragment>
              )
            })}
          </form>
        </CardContent>
        {activeSection == lastSection &&
          <Typography component="div" className='sig-container'>
            <Typography
              component="div"
              className={cl(
                'contents',
                sigRequried && 'sig-requried'
              )}
            >
              {hideOldSignature ? 
                <SignatureCanvas 
                  penColor='black'
                  canvasProps={{ className: 'sig-canvas' }}
                  ref={sigCanvas}
                  clearOnResize={true}
                />     
                :
                signature && <>
                  {sigDataURL ? <img src={sigDataURL} />
                  : <img src={`data:image/png;base64,${signature}`}/>}
                </>
              }              
              <Button onClick={clearSignature} className="clear-button">Clear</Button>
              <Button onClick={saveSignature} className="save-button">Save</Button>
            </Typography>
          </Typography>
        }
        <hr className='hr-label'/>
        {isShowAlertMessage &&
          <Stack sx={{ width: '100%' }} spacing={2}>
            <Alert severity="error">Make sure you've fixed all issues above</Alert>
          </Stack>
        }
        <Grid container spacing={1} className='next-button'>
          <Grid xs={12} md={4} item={true}>
            {activeSection > 0 && 
              <Button variant="contained" color="primary" size="lg" onClick={() => setActiveSection(activeSection - 1)} className='btn-section'>
                <ArrowBackIcon className='back-icon'/>
                <Typography component="div" className='back'>Prev Step</Typography>
              </Button>
            }
          </Grid>
          <Grid xs={12} md={4} item={true}></Grid>
          <Grid xs={12} md={4} item={true} >
            {activeSection < lastSection && 
              <Button variant="contained" color="primary" size="lg" onClick={handleNextStep} className='btn-section'>
                <Typography component="div" className='forward'>Next Step</Typography>
                <ArrowForwardIcon className='forward-icon'/>
              </Button>
            }
            {activeSection == lastSection && <Button variant="contained" size="lg" onClick={handleComplete} className="btn-section">complete </Button>}
          </Grid>
        </Grid>
        <hr />
        <CardActions className='footer'>
          <Typography component="div" className="copy-right">
            {branding &&
              <Box
                sx={{
                  flexGrow: 1,
                  backgroundPosition: 'center',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundImage: `url(/common/companyfiles.php?logo=1&filename=${branding.logoPrimary})`,
                  height: 24,
                  marginBottom: '8px'
                }}
              />
            }
            {companyName}, {address.streetaddress}, {address.city}, {address.state} {address.zipcode}
          </Typography>
        </CardActions>
      </Card>
      <Snackbar open={showSnackbar} autoHideDuration={4000} onClose={closeSnackbar}>
        <Alert onClose={closeSnackbar} severity={snackbarType} sx={{ width: '100%' }}>
          {alertContent}
        </Alert>
      </Snackbar>
    </>
  );
}
