import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import useCompany from 'common/company';
import useLocalStorage from '@twipped/hooks/useLocalStorage';

import './styles.scss'

import { useDocument } from 'core'
import { Document, DocElementType } from 'core/models'

import {
  StepsHeader, Sidebar,
} from 'components'
import { SectionEl } from './section'
import { orderBy } from 'lodash';

export function HomePage(props) {
  const { category, nextcategory, lastcategory } = useParams()
  const { curDocument, loadDocument, getParticipantSignature, signature, orderInfo, loadOrderInfo } = useDocument()
  const [question, setQuestion] = React.useState(null)
  const [sectionRoutes, setSectionRoutes] = React.useState([])
  const [questionIdx, setQuestionIdx] = React.useState(0)
  const [activeSection, setActiveSection] = React.useState(0)
  const [signatures, setSignatures] = React.useState(null)
  const [sigDataURL, setSigDataURL] = React.useState(null)
  const [sidebar, setSidebar] = React.useState(null)
  const [orderId, setOrderId] = React.useState(0)
  const [partId, setPartId] = React.useState(0)
  const [partsUUID, setPartsUUID] = React.useState()
  const { loading, name, branding, address } = useCompany();
  const [ participant, setParticipant ] = useLocalStorage('participant');
  const [isShowAlertMessage, setShowAlertMessage] = React.useState(false)
  const [isShowSubmissionPage, setShowSubmissionPage] = React.useState(false)

  React.useEffect(() => {
    loadOrderInfo()
  }, [])

  React.useEffect(() => {
    setSignatures(signature)
  }, [signature])

  React.useEffect(() => {
    const question = curDocument?.calcQuestion(questionIdx)
    setQuestion(question)
    if (question) {
      var sectionRoutes = []
      question.sections.map((section) => {
        if (section.section_label !== "")
          sectionRoutes.push({
            path: section.section_label.replace(/\s+/g,"").toLowerCase(),
            label: section.section_label.toLowerCase()
          })
      })
      setSectionRoutes(Object.freeze(sectionRoutes))
      if (participant && participant.uuid == partsUUID) {
        setActiveSection(participant.stepper)
        if (participant.complete) setShowSubmissionPage(participant.complete)
      }
      getParticipantSignature()
    }
  }, [curDocument])

  React.useEffect(() => {
    if (orderInfo) {
      setSidebar(orderInfo.order)
      if (orderInfo.order.orderid) setOrderId(orderInfo.order.orderid)
      if (orderInfo.order.partid) setPartId(orderInfo.order.partid)
      if (orderInfo.order.participant_uuid) {
        setPartsUUID(orderInfo.order.participant_uuid)
        loadDocument(orderInfo.order.participant_uuid)
      }
    }
  }, [orderInfo])

  const setSectionStepper = (stepper, complete=false) => {
    if (!complete)
      setActiveSection(stepper)
    setParticipant({
      uuid: partsUUID,
      stepper: stepper,
      complete: complete
    })
  }

  let isNextStep

  const UpdateOrderParticipantQuestion = (element, is_selected) => {
    let re, value = element.value != null ? element.value : element.user_value;
    element.error = false;
    element.error_message = ''

    if (value === undefined || !value) {
      if (is_selected && element.required) {
        element.error = true;
        isNextStep = false
      }
    } else {
      if (element.match_pattern) {
        re = new RegExp(element.match_pattern)
        if (!re.test(value)) {
          if (is_selected) isNextStep = false
          element.error = true;
        } else {
          if (element.element_name == 'core_cc_exp') {
            const d = new Date()
            const m = parseInt(value.split("/")[0]), y = parseInt(value.split("/")[1])
            if (m > 12 || y < d.getFullYear() || y > d.getFullYear()+100 || (m<d.getMonth()+1 && y==d.getFullYear())) {
              if (is_selected) isNextStep = false
              element.error = true;
            }
          }
        }
      }

      if (is_selected && element.element_type !== DocElementType.File && element.element_type !== DocElementType.Upload && !element.error && element.value && element.user_value != element.value)
        Document.UpdateOrderParticipantQuestion(partId, element, partsUUID)
    }
    if (is_selected)
      for (let option of element.options)
        for (let sub_element of option.elements)
          UpdateOrderParticipantQuestion(sub_element, value == option.option_name)
  }

  const checkNextStep = () => {
    if (question?.sections[activeSection]) {
      isNextStep = true
      for (let group of question?.sections[activeSection].groups) {
        for (let element of group.elements) {
          UpdateOrderParticipantQuestion(element, true)
        }
      }

      if (isNextStep)
        setShowAlertMessage(false)
      else
        setShowAlertMessage(true)

      return isNextStep
    }
    return false
  }

  return (
    <React.Fragment>
      <Container className='main-container'>
        <StepsHeader
          steps={sectionRoutes}
          activeSection={activeSection}
          setActiveSection={setSectionStepper}
          sectionCount={sectionRoutes.length}
          branding={branding}
          checkNextStep={checkNextStep}
        />
        <Grid container spacing={1}>
          <Grid xs={12} md={8} item={true} >
            {question?.sections.map((section, sectionIdx) => {
              if (section.section_label == '') return
              if (!section || sectionIdx !== activeSection) return;
              return (
                <SectionEl
                  key={sectionIdx}
                  section={section}
                  sectionIdx={sectionIdx}
                  sectionCount={sectionRoutes.length}
                  activeSection={activeSection}
                  setActiveSection={setSectionStepper}
                  signature={signatures}
                  sigDataURL={sigDataURL}
                  setSigDataURL={setSigDataURL}
                  branding={branding}
                  companyName={name}
                  address={address}
                  orderId={orderId}
                  partId={partId}
                  checkNextStep={checkNextStep}
                  isShowAlertMessage={isShowAlertMessage}
                  isShowSubmissionPage={isShowSubmissionPage}
                  setShowSubmissionPage={setShowSubmissionPage}
                />
              )
            })}
          </Grid>
          <Grid xs={12} md={4} item={true} >
            <Sidebar contents={sidebar}/>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  )
}
